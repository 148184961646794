import '../assets/css/footer.css';
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-primary-gradient">
        <div className="container py-4 py-lg-5">
        <hr />
            <div className="row justify-content-center">
                <div className="col-sm-4 col-md-6 col-lg-12 text-center text-lg-start d-flex flex-row item">
                    <ul className="list-unstyled d-flex flex-row justify-content-between">
                        <li style={{marginRight: 20 + 'px'}}><a href="imprint">Imprint</a></li>
                        <li style={{marginRight: 20 + 'px'}}><a href="privacy">Privacy</a></li>
                        <li style={{marginRight: 20 + 'px'}}><a href="https://discord.gg/ahA5bGVrgP">Community</a></li>
                    </ul>
                </div>
            </div>
            <div className="text-muted d-flex justify-content-between align-items-center pt-3">
                <p className="mb-0">Copyright © 2024</p>
                <ul className="list-inline mb-0">
                </ul>
            </div>
        </div>
    </footer>
  );
}

export default Footer;
