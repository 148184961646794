import React, { useRef, useState } from "react";
import { Editor } from '@monaco-editor/react';


const EditorComponent = ({ runHandler, validateHandler, isSandbox }) => {
    const [content, setContent] = useState("SELECT *\nFROM test\n//write your query");
    const monacoRef = useRef(null);

    function handleEditorWillMount(monaco) {
        monaco.languages.register({ id: 'pgsql' });

        monaco.languages.setMonarchTokensProvider('pgsql', {
            tokenizer: {
              root: [
                [/SELECT|INSERT|UPDATE|DELETE|FROM|WHERE|GROUP BY|ORDER BY|HAVING/, 'keyword'],
                { include: '@whitespace' },
                [/[a-zA-Z\_]\w*/, 'identifier'],
                [/'/, {
                  cases: {
                    '@eos': 'string',
                    '@default': { token: 'string', next: '@string' }
                  }
                }],
                [/\d+/, 'number']
              ],
              whitespace: [
                [/[ \t\r\n]+/, 'white'],
                [/(^--.*$)/, 'comment'],
                [/\/\*/, { token: 'comment', next: '@comment' }]
              ],
              comment: [
                [/[^/*]+/, 'comment'],
                [/\*\//, 'comment', '@pop'],
                [/./, 'comment']
              ],
              string: [
                [/[^']+/, 'string'],
                [/'/, { token: 'string', next: '@pop' }]
              ]
            }
          });

        monaco.languages.registerCompletionItemProvider('pgsql', {
            provideCompletionItems: () => {
                return {
                    suggestions: [
                        {
                            label: 'SELECT',
                            kind: monaco.languages.CompletionItemKind.Keyword,
                            insertText: 'SELECT',
                            detail: 'Retrieve data from a table',
                        },
                        {
                            label: 'FROM',
                            kind: monaco.languages.CompletionItemKind.Keyword,
                            insertText: 'FROM',
                            detail: 'Specify the table to retrieve data from',
                        },
                    {
                        label: 'INSERT',
                        kind: monaco.languages.CompletionItemKind.Keyword,
                        insertText: 'INSERT',
                        detail: 'Insert data into a table',
                    },
                    {
                        label: 'UPDATE',
                        kind: monaco.languages.CompletionItemKind.Keyword,
                        insertText: 'UPDATE',
                        detail: 'Update data in a table',
                    },
                    {
                        label: 'DELETE',
                        kind: monaco.languages.CompletionItemKind.Keyword,
                        insertText: 'DELETE',
                        detail: 'Delete data from a table',
                    },
                    {
                        label: 'WHERE',
                        kind: monaco.languages.CompletionItemKind.Keyword,
                        insertText: 'WHERE',
                        detail: 'Filter data based on a condition',
                    },
                    {
                        label: 'ORDER BY',
                        kind: monaco.languages.CompletionItemKind.Keyword,
                        insertText: 'ORDER BY',
                        detail: 'Sort the result set',
                    },
                    {
                        label: 'GROUP BY',
                        kind: monaco.languages.CompletionItemKind.Keyword,
                        insertText: 'GROUP BY',
                        detail: 'Group rows that have the same values into summary rows',
                    },
                    {
                        label: 'HAVING',
                        kind: monaco.languages.CompletionItemKind.Keyword,
                        insertText: 'HAVING',
                        detail: 'Filter groups based on a condition',
                    },
                    ]
                };
            }
        });
    }
  
    function handleEditorDidMount(editor, monaco) {
      // here is another way to get monaco instance
      // you can also store it in `useRef` for further usage
      monacoRef.current = monaco;
    }


    const handleChange = (value) => {
        setContent(value);
    };

    const options = {
        selectOnLineNumbers: true,
        lineHeight: 21,
        fontSize: 19,
        lineDecorationsWidth: 0,
        selectOnLineNumbers: true,
        roundedSelection: false,
        readOnly: false,
        cursorStyle: 'line',
        automaticLayout: true,
        minimap: {
            enabled: false,
        },
        scrollbar: {
            useShadows: false,
            verticalHasArrows: true,
            horizontalHasArrows: true,
            vertical: "hidden",
            horizontal: "hidden",
            verticalScrollbarSize: 17,
            horizontalScrollbarSize: 17,
            arrowSize: 30,
        }
    }

    return (
        <div className="mt-2">
            <Editor
                height="25vh"
                defaultLanguage="pgsql"
                theme='vs-light' // vs-dark
                value={content}
                onChange={handleChange}
                defaultValue="SELECT *"
                beforeMount={handleEditorWillMount}
                onMount={handleEditorDidMount}
                options={options}
            />

        <div className="bg-white">
            <button className="btn btn-info" style={{ margin: '10px'}} onClick={runHandler} value={content}>Run Query</button>
            {isSandbox ? <span></span> : <button className="btn btn-primary" onClick={validateHandler} value={content}>Submit</button>}
        </div>
        </div>
    );
}

export default EditorComponent;
