import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Index from './components/Index';
import TrainerTasks from './components/TrainerTasks'
import Trainer from './components/Trainer';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Sandbox from './components/Sandbox';
import Handbook from './components/Handbook';
import Course from './components/Course';
import CourseChapter from './components/CourseChapter';
import HandbookFunction from './components/HandbookFunction';
import Imprint from './components/Imprint';
import Privacy from './components/Privacy';

function App() {
  return (
    <>
    <Navigation />
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/trainer/tasks/:id" element={<TrainerTasks />} />
        <Route path="/trainer/" element={<Trainer />} />
        <Route path="/sandbox/" element={<Sandbox />} />
        <Route path="/handbook/" element={<Handbook />} />
        <Route path="/handbook/:id" element={<HandbookFunction />} />
        <Route path="/course/" element={<Course nItems={undefined} />} />
        <Route path="/course/:moduleId/:chapterId" element={<CourseChapter />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </Router>
    <Footer />
    </>
  );
}

export default App;
