import React from 'react';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import "../assets/css/course.css";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw"
import { flushSync } from 'react-dom'
import { API_URL } from '../Config';


const copyToClipboard = async (text) => {
  
    try {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Text copied to clipboard' + text);
        alert('Copied to clipboard');
      }).catch((err) => {
        console.error('Could not copy text: ', err);
      });
      return text;
    } catch (error) {
      console.error(error);
      return "No data found";
    }
  };

const CourseChapter = () => {  
  let { moduleId, chapterId } = useParams(); 
  const [chapterContent, setChapterContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  async function getStaticProps() {
      try {
          setIsLoading(true);
          const response = await fetch(
              API_URL + "/content/fetch-markdown?chapter_id=" + chapterId,
              {cache: "no-store"},
          );

          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          setChapterContent(data.content);
          setError(null);
        
          flushSync(() => {          
            if (window.highlightAll) {
                window.highlightAll();
            } 
            window.copyToClipboard = copyToClipboard;
            const codeBlocks = document.querySelectorAll('pre');
            codeBlocks.forEach(block => {
                block.addEventListener('click', () => {
                    copyToClipboard(block.textContent);
                });
            });
        });
          return {
              props: {
                  blockMap: data
              }
          };
      } catch (error) {
          setError(error.message);
          setChapterContent('');
      } finally {
          setIsLoading(false);
      }
  }
  
  useEffect(() => {
        getStaticProps();
  }, [chapterId]); // Changed dependency to chapterId

  useEffect(() => {
      if (chapterContent) {
          const images = document.querySelectorAll('img');
          images.forEach(img => {
              img.classList.add('img-fluid', 'd-block', 'mx-auto');
          });
      }
  }, [chapterContent]);

  if (isLoading) {
      return (
          <div className="container custom-container">
              <p>Loading...</p>
          </div>
      );
  }

  if (error) {
      return (
          <div className="container custom-container">
              <h2>Error</h2>
              <p>Failed to load content: {error}</p>
          </div>
      );
  }

  if (!chapterContent) {
    return (
        <div className="container custom-container">
            <h2>Not Found</h2>
            <p>The requested chapter content could not be found.</p>
        </div>
    );
}

return (
    <div className='container custom-container'>
        <ReactMarkdown 
            children={chapterContent}  
            rehypePlugins={[rehypeRaw]} 
            remarkPlugins={[remarkGfm]} 
        />
    </div>
);
}

export default CourseChapter;
