import React from 'react';

const Imprint = () => {
    return (
        <div className="container">
            <h1>Imprint</h1>

            <div class="section">
                <h2>Website Information</h2>
                <p>sql-expert.org</p>
            </div>

            <div class="section">
                <h2>Responsible for Content</h2>
                <p>Aleksandr Perevalov<br />
                    Vladislav Raskoshinskii</p>
            </div>

            <div class="section">
                <h2>Contact</h2>
                <p>Email: contact@perevalov.com</p>
            </div>

            <div class="section">
                <h2>Legal Notice</h2>
                <p>This website (sql-expert.org) is created and maintained by Aleksandr Perevalov and Vladislav Raskoshinskii. All content, unless otherwise stated, is the intellectual property of the website owners.</p>
            </div>

            <div class="section">
                <h2>Disclaimer</h2>
                <p>The information contained on this website is provided for general informational purposes only. While we strive to keep the information up to date and accurate, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information contained on the website.</p>
            </div>

            <div class="section">
                <h2>Copyright Notice</h2>
                <p>© 2024 sql-expert.org. All rights reserved. The content of this website, including but not limited to texts, graphics, images, and code, is protected by copyright law.</p>
            </div>

            <div class="section">
                <h2>Changes and Modifications</h2>
                <p>We reserve the right to modify the contents of this website at any time without prior notice. By using our website, you agree to be bound by the current version of these terms and conditions.</p>
            </div>

        </div>
    );
}

export default Imprint;
