import React from 'react';
import '../assets/css/navigation.css'

const Navigation = () => {
  return (
    <nav className="navbar navbar-light navbar-expand-lg py-3 bg-light shadow-sm">
        <div className="container">
            <a className="navbar-brand" href="/">SQL EXPERT 🎓</a>
            <button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navcol-1">
                <span className="visually-hidden">Toggle navigation</span>
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navcol-1">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <a className="nav-link" href="/course">Course</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/trainer">Gym</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/handbook">Handbook</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/sandbox">Sandbox</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://discord.gg/ahA5bGVrgP">Community</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  );
}

export default Navigation;
