import React from 'react';
import '../assets/css/trainer.css'
import { useEffect, useState } from 'react';
import { API_URL } from '../Config';

const Trainer = () => {
    const [taskList, setTaskList] = useState([]);
    const [isCheckedEasy, setCheckedEasy] = useState(false);
    const [isCheckedMedium, setCheckedMedium] = useState(false);
    const [isCheckedHard, setCheckedHard] = useState(false);
    const [isCheckedCompanyTask, setCheckedCompanyTask] = useState(false);

    const fetchTaskList = async () => {
        const response = await fetch(API_URL + '/tasks');
        const data = await response.json();

        if (isCheckedEasy || isCheckedMedium || isCheckedHard || isCheckedCompanyTask){
            const filteredTaskList = data.filter(task => {
                return (isCheckedEasy && task.difficulty === 'Easy') || (isCheckedMedium && task.difficulty === 'Medium') || (isCheckedHard && task.difficulty === 'Hard') || (isCheckedCompanyTask && task.is_company_task === true);
            });
            setTaskList(filteredTaskList);
        } else {
            setTaskList(data);
        }
    };

    // useEffect(() => {
    //     fetchTaskList();
    // }, []);

    useEffect(() => {
        fetchTaskList();
        const filteredTaskList = taskList.filter(task => {
            return (isCheckedEasy && task.difficulty === 'Easy') || (isCheckedMedium && task.difficulty === 'Medium') || (isCheckedHard && task.difficulty === 'Hard') || (isCheckedCompanyTask && task.is_company_task === true);
        });
        setTaskList(filteredTaskList);
    }, [isCheckedEasy, isCheckedMedium, isCheckedHard, isCheckedCompanyTask]);

  const getDifficultyClass = (diffClass) => {
    if (diffClass === 'Easy') {
        return 'i-easy'
    }
    if (diffClass === 'Medium') {
        return 'i-middle'
    }
    if (diffClass === 'Hard') {
        return 'i-hard'
    }
  }

  const openTask = (taskId) => {
    window.location.href = "trainer/tasks/" + taskId;
  }

  return (
    <div class="container mt-4">
        <div class="row">
            <div class="col-md-8">
                <div class="header">Gym Exercises 🏋️‍♂️</div>
                <div class="task-list">
                {taskList.map(task => (     
                    <div class="task-item" onClick={() => openTask(task.number)}>
                        <i class={`fas fa-circle ${getDifficultyClass(task.difficulty)}`}></i>
                        <div class="task-text" dangerouslySetInnerHTML={{__html: '#'+task.number + ' ' + task.description}}></div>
                    </div>
                ))}
                </div>
            </div>
            <div class="col-md-4">
                <div class="sidebar">
                    <div class="checkbox-group">
                        <label>📶 Level</label>
                        <div><input type="checkbox" id="easy" checked={isCheckedEasy} onChange={(e) => setCheckedEasy(e.target.checked)}></input> <label for="easy">Easy</label></div>
                        <div><input type="checkbox" id="medium" checked={isCheckedMedium} onChange={(e) => setCheckedMedium(e.target.checked)}></input> <label for="medium">Medium</label></div>
                        <div><input type="checkbox" id="hard" checked={isCheckedHard} onChange={(e) => setCheckedHard(e.target.checked)}></input> <label for="hard">Hard</label></div>
                    </div>
                    <div class="checkbox-group">
                        <label>ℹ️ Other filters</label>
                        <div><input type="checkbox" id="company" checked={isCheckedCompanyTask} onChange={(e) => setCheckedCompanyTask(e.target.checked)}></input><label for="company">Exercises from interviews</label></div>
                    </div>
                </div>
            </div>
        </div>
        </div>

  );
}

export default Trainer;
