import React from 'react';
import Split from 'react-split'
import EditorComponent from './Editor';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import { API_URL } from '../Config';

import '../assets/css/trainerTasks.css'
import "../assets/css/resizable.css";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TrainerTasks = () => {
    let { id } = useParams();
    const [db, setDb] = React.useState('air_trips_db');
    let [task, setTask] = useState(null);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');

    useEffect(() => {
      const fetchFunction = async () => {
        const response = await fetch(API_URL + '/tasks/' + id);
        const data = await response.json();
        setTask(data[0]);
        setDb(data[0].db_name);
        console.log(data)
      };
      fetchFunction();
    }, [id]);

    const runHandler = (e) => {
      const generateData = async () => {
        const response = await fetch(API_URL + '/dbs/' + db + '/execute', {
          method: 'POST',
          headers: {
            "accept": "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({"query": e.target.value})
        });
        setPage(0);
        const data = await response.json();
        if ("error" in data) {
          setRows([{ error: data.error }]);
          setColumns([{ id: 'error', label: 'Error', minWidth: 100 }]);
          return;
        }
        console.log(data);
        setRows(data.rows);
        const firstRow = data.rows[0];
        const columnsList = Object.keys(firstRow).map(key => ({ id: key, label: key, minWidth: 100 }));
        console.log(columnsList);
        setColumns(columnsList);
      };
      generateData();
    };

    const validateHandler = (e) => {
      console.log(e.target.value)
      const validateData = async () => {
        const responseRows = await fetch(API_URL + '/dbs/' + db + '/execute', {
          method: 'POST',
          headers: {
            "accept": "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({"query": e.target.value})
        });
        setPage(0);

        const executeData = await responseRows.json() 
        
        if ("error" in executeData) {
          setRows([{ error: executeData.error }]);
          setColumns([{ id: 'error', label: 'Error', minWidth: 100 }]);
          return;
        }

        const result = executeData.rows;

        setRows(result);
        const firstRow = result[0];
        const columnsList = Object.keys(firstRow).map(key => ({ id: key, label: key, minWidth: 100 }));
        setColumns(columnsList);


        const response = await fetch(API_URL + '/tasks/' + id + '/validate', {
          method: 'POST',
          headers: {
            "accept": "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({"result": result})
        });
        const data = await response.json();
        //alert(JSON.stringify(data));
        if ("OK" in data) {
          setAlertSeverity('success');
          setAlertContent('Query is correct');
        } else {
          setAlertSeverity('error');
          setAlertContent('Query is incorrect. ' + data.error);
        }
        setAlert(true);
      };
      validateData();
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  return (

    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Database schema
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          
          </Typography>
        </Box>
      </Modal>
      <Split
          className="wrap"
          sizes={[35, 65]}
          minSize={200}
          expandToMin={false}
          gutterSize={5}
          gutterAlign="center"
          snapOffset={10}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
      >  
        <div className="container bg-light">
          <h4 className='mt-2'>🏋️‍♂️ Exercise {task?.number}</h4>
          <aside dangerouslySetInnerHTML={{__html: task?.description}}></aside>
          {task?.hint && task.hint.length > 0 && (
            <div>
              <h5 className='mt-4'>ℹ️ Hints:</h5>
              <aside>
              {task.hint.map((hint, index) => (
                <span key={index} dangerouslySetInnerHTML={{__html: hint}}></span>
              ))}
              </aside>
            </div>
          )}
          <h5>✅ Expected result columns:</h5>
          <ul>
          {task?.result_columns.map((column, index) => (
            <li key={index}><code>{column}</code></li>
          ))}
          </ul>
          <hr></hr>
          <iframe width="100%" height="400px" allowtransparency="true" allowfullscreen="true" scrolling="no" title="Embedded DrawSQL IFrame" frameborder="0" src={"https://drawsql.app/teams/sql-expert-1/diagrams/" + db.replaceAll("_", "-") + "/embed"}></iframe>
      </div>
        <div className='bg-white'>
            <EditorComponent isSandbox={false} runHandler={runHandler} validateHandler={validateHandler}  />
            <hr className='hr hr-blurry mt-0'></hr>
            <div>
              {alert ? <Alert severity={alertSeverity} onClose={() => {setAlert(false)}}>{alertContent}</Alert> : <></> }
              <h5 className="result mt-2" style={{marginLeft: '5px'}}>Query result</h5>
              <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth, fontWeight: 'bold', fontFamily: 'monospace' }}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                    <TableCell key={column.id} align={column.align}  style={{ fontFamily: 'monospace'}}>
                                    {column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                );
                                })}
                            </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
          </div>
        </div>
      </Split>
    </div>
  );
}

export default TrainerTasks;
