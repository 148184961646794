import React from 'react';
import "../assets/css/handbook.css";
import { useEffect, useState } from 'react';
import { API_URL } from '../Config';


const Handbook = () => {
  const [stringFunctions, setStringFunctions] = useState([]);
  const [numericFunctions, setNumericFunctions] = useState([]);
  const [dateTimeFunctions, setDateTimeFunctions] = useState([]);
  const [advancedFunctions, setAdvancedFunctions] = useState([]);
  const [windowFunctions, setWindowFunctions] = useState([]);

  useEffect(() => {
    const fetchFunctions = async () => {
      const response = await fetch(API_URL + '/handbook');
      const data = await response.json();
      setStringFunctions(data.filter(func => func.function_group === 'String functions'));
      setNumericFunctions(data.filter(func => func.function_group === 'Numeric functions'));
      setDateTimeFunctions(data.filter(func => func.function_group === 'Date and time functions'));
      setAdvancedFunctions(data.filter(func => func.function_group === 'Advanced functions'));
      setWindowFunctions(data.filter(func => func.function_group === 'Window functions'));
    };
    fetchFunctions();
  }, []);

  console.log(stringFunctions);
  return (
    <div className="container">
        <div className="functions-card bg-white">
            <h3 className='mt-3'>🦮 SQL Functions Reference Guide</h3>
            <div className="card-body">
                <p className='fw-bold mt-4'>String functions</p>
                <div className="functions">
                {stringFunctions.map(func => (
                    <span key={func.title} style={{marginRight: '3px', color: 'grey'}}><a href={'/handbook/' + func.title } className='ignore-underline'>{func.title}</a> </span> 
                ))}
                </div>
                <p className='fw-bold mt-4'>Numeric functions</p>
                <div className="functions">
                {numericFunctions.map(func => (
                    <span key={func.title} style={{marginRight: '3px', color: 'grey'}}><a href={'/handbook/' + func.title } className='ignore-underline'>{func.title}</a> </span> 
                ))}
                </div>
                
                <p className='fw-bold mt-4'>Date and time functions</p>
                <div className="functions">
                {dateTimeFunctions.map(func => (
                    <span key={func.title} style={{marginRight: '3px', color: 'grey'}}><a href={'/handbook/' + func.title } className='ignore-underline'>{func.title}</a> </span> 
                ))}
                </div>
                
                <p className='fw-bold mt-4'>Advanced functions</p>
                <div className="functions">
                {advancedFunctions.map(func => (
                    <span key={func.title} style={{marginRight: '3px', color: 'grey'}}><a href={'/handbook/' + func.title } className='ignore-underline'>{func.title}</a> </span> 
                ))}
                </div>
                
                <p className='fw-bold mt-4'>Window functions</p>
                <div className="functions">
                {windowFunctions.map(func => (
                    <span key={func.title} style={{marginRight: '3px', color: 'grey'}}><a href={'/handbook/' + func.title } className='ignore-underline'>{func.title}</a> </span> 
                ))}
                </div>
            </div>
        </div>
    </div>
  );
}

export default Handbook;
