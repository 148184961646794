import React from 'react';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import "../assets/css/handbook.css";
import { API_URL } from '../Config';

const HandbookFunction = () => {
  let { id } = useParams(); 
  let [_function, setFunc] = useState(null);

  useEffect(() => {
    const fetchFunction = async () => {
      console.log(id);
      const response = await fetch(API_URL + '/handbook/' + id);
      const data = await response.json();
      setFunc(data[0]);
    };
    fetchFunction();
  }, []);

  return (
    <div className="container">
      <h3 className='mt-3'>🦮 SQL Functions Reference Guide</h3>
        <div className="functions-card bg-white">
            <div className="card-body">
                <h4>{id}</h4>
                <div className="functions">
                  {_function?.descriptions?.map(descr => (
                    <p>{descr}</p>
                  ))}
                  
                  <code>
                    {_function?.code_snippets[0]}
                  </code>
                  
                  { _function?.param_names.length > 0 && (
                    <p className='fw-bold mt-3'>Params: </p>
                  )}
                  
                  {Array.from({ length: _function?.param_names?.length }, (_, index) => (
                    <div key={index}>
                      <p><code>{_function.param_names[index]}</code> — {_function.param_descriptions[index]}</p>
                    </div>
                  ))}

                  { _function?.code_snippets.length > 0 && (
                    <p className='fw-bold mt-3'>Examples: </p>
                  )}

                  {_function?.code_snippets.slice(1).map((snippet, index) => (
                    <>
                      <code key={index}>{snippet}</code>
                      <br />
                    </>
                  ))}
                  <br />
                  {_function?.post_descriptions.map((descr, index) => (
                    <>
                      {descr?.includes("<table>") ? 
                        <div key={index} dangerouslySetInnerHTML={{ __html: descr }} />
                       : 
                       <code key={index}>{descr}</code>
                      }
                      
                      <br />
                    </>
                  ))}
                </div>
            </div>
        </div>
    </div>
  );
}

export default HandbookFunction;
