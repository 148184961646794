import "../assets/css/index.css";

import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Course from '../components/Course';

const Index = () => {
  return (
    <div class="container">
        <h1 class="main-heading">SELECT <span style={{color: 'red'}}>KNOWLEDGE</span> <br></br>FROM <span style={{color: 'red'}}>SQL_EXPERT</span>;</h1>
        <div class="row">
            <div class="col-md-4">
                <div class="card shadow-sm">
                    <a href="/course"><img src="/static/course-card.jpg" class="card-img-top" alt="Placeholder image with dimensions 300x200px" /></a>
                    <h5 class="card-title">SQL Course</h5>
                    <p class="card-text">An interactive SQL Course with practical assignments including audio podcast for every chapter</p>
                    <a href="/course" class="btn">Learn</a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card shadow-sm">
                    <a href="/trainer"><img src="/static/gym-card.jpg" class="card-img-top" alt="Placeholder image with dimensions 300x200px" /></a>
                    <h5 class="card-title">SQL Gym</h5>
                    <p class="card-text">A large database with practical exercises including the ones from company interviews</p>
                    <a href="/trainer" class="btn">Work out</a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card shadow-sm">
                    <img src="/static/tutor-card.jpg" class="card-img-top" alt="Placeholder image with dimensions 300x200px" />
                    <h5 class="card-title">SQL AI Tutor</h5>
                    <p class="card-text">A self-paced learning environment with an AI-powered assistant</p>
                    <a href="#" class="btn">Coming soon...</a>
                </div>
            </div>
        </div>
        
        <h2 className="fw-bold text-center mt-5">Learn SQL for your career in our free course</h2>
        <div className='card-main bg-light rounded'>
            <div class="row">
                <Course nItems={2}/>
            </div>
        </div>

        <h2 className="fw-bold text-center mt-5">Frequently Asked Questions</h2>
        <div className='card-course'>
            <Accordion className="accordionMui">
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>What distinguishes this course from others?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                SQL Expert offers a unique learning experience by combining:
                <ul>
                    <li>A practical, hands-on approach with real-world SQL challenges</li>
                    <li>Personalized AI tutoring that adapts to your learning style</li>
                    <li>An integrated platform with course, gym, and handbook all in one place</li>
                    <li>Community-driven, open-source content that's always up-to-date</li>
                    <li>Completely free access to professional-grade materials</li>
                </ul>

                Unlike traditional courses, we focus on practical skills through interactive exercises while providing instant feedback and personalized support through our AI tutor 
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className="accordionMui">
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                >
                <Typography>What skill level is the course suitable for?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Our course is designed to accommodate multiple skill levels, from beginners to advanced users. The content is structured progressively, starting with basic SQL concepts and advancing to complex queries and database optimization. Users can start at their appropriate level and progress at their own pace.
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className="accordionMui">
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                >
                <Typography>How often is the content updated, and can I contribute to the platform?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    The content is regularly updated by our community to reflect current best practices and new SQL features. We welcome contributions from users! You can contribute by submitting pull requests on our GitHub repository, suggesting new exercises for the SQL Gym, improving documentation, or helping to enhance the handbook content. All contributions are reviewed by our maintainers to ensure quality and accuracy.
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className="accordionMui">
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
                >
                <Typography>Is SQL Expert completely free to use?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                Yes, SQL Expert is completely free and open-source. Our platform is maintained by the community, and we believe in making quality SQL education accessible to everyone. You can access all features including the course materials, SQL gym, handbook, and AI tutor without any payment.
                </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    </div>
  );
}

export default Index;
